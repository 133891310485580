// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "LinkWithDescriptionComponent-module--aboutMainCircleBlurBlue--6536e";
export var bHeading = "LinkWithDescriptionComponent-module--bHeading--76f72";
export var development = "LinkWithDescriptionComponent-module--development--741e3";
export var gdnPromotePic = "LinkWithDescriptionComponent-module--gdnPromotePic--6a3ef";
export var head = "LinkWithDescriptionComponent-module--head--e7aad";
export var heroBackgroundCircleLeft = "LinkWithDescriptionComponent-module--heroBackgroundCircleLeft--0a634";
export var heroBackgroundCircleLeftStory = "LinkWithDescriptionComponent-module--heroBackgroundCircleLeftStory--da9ea";
export var heroBackgroundCircleLeftStorySecond = "LinkWithDescriptionComponent-module--heroBackgroundCircleLeftStorySecond--8332a";
export var heroBackgroundInnerCircleLeft = "LinkWithDescriptionComponent-module--heroBackgroundInnerCircleLeft--18572";
export var heroBackgroundInnerCircleLeftSecond = "LinkWithDescriptionComponent-module--heroBackgroundInnerCircleLeftSecond--cdd30";
export var knowUs = "LinkWithDescriptionComponent-module--knowUs--1740c";
export var knowUsHeading = "LinkWithDescriptionComponent-module--knowUsHeading--e8905";
export var knowUsImage = "LinkWithDescriptionComponent-module--knowUsImage--e6674";
export var knowUsImageDiv = "LinkWithDescriptionComponent-module--knowUsImageDiv--8bd27";
export var knowUsOurStory = "LinkWithDescriptionComponent-module--knowUsOurStory--de539";
export var knowUsOurStoryText = "LinkWithDescriptionComponent-module--knowUsOurStoryText--1c3e7";
export var knowUsText = "LinkWithDescriptionComponent-module--knowUsText--c8ec6";
export var ourStoryDescPara = "LinkWithDescriptionComponent-module--ourStoryDescPara--6e027";
export var ourStoryImg = "LinkWithDescriptionComponent-module--ourStoryImg--9c05a";
export var ourStoryImgDiv = "LinkWithDescriptionComponent-module--ourStoryImgDiv--7ba5c";
export var ourStoryText = "LinkWithDescriptionComponent-module--ourStoryText--fac2c";
export var storyPromotePic = "LinkWithDescriptionComponent-module--storyPromotePic--8982f";
export var storyPromotePicSecond = "LinkWithDescriptionComponent-module--storyPromotePicSecond--3a643";
export var testimonialVideo = "LinkWithDescriptionComponent-module--testimonialVideo--b4294";
export var textWraper = "LinkWithDescriptionComponent-module--textWraper--c1ee4";
export var topHeading = "LinkWithDescriptionComponent-module--topHeading--afc30";